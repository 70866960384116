function getIdMapping(arrayData) {
    const idMapping = arrayData.reduce((acc, el, i) => {
        acc[el.typeNumber] = i;
        return acc;
    }, {});
    return idMapping;
}
let menuRoot = {}; //根对象
function getMenuDataBuild(arrayData, rootParentId) {
    if (!rootParentId) {
        rootParentId = -100;
    }
    if (!arrayData) {
        arrayData = [];
    }

    var menu = [];
    arrayData.forEach((el) => {
        // 判断根节点
        if (el.typeNumber == rootParentId) {
            menu.push(el)
            menuRoot = el
            return;
        }
        //console.log(el.parentId)
        // console.log(this.getIdMapping[el.parentId])
        // 用映射表找到父元素

        const parentEl = arrayData[getIdMapping(arrayData)[el.parentNumber]];

        // 把当前元素添加到父元素的`children`数组中
        parentEl.children = [...(parentEl.children || []), el];
    });
    return { menu: menu, menuRoot: menuRoot };
}


export default getMenuDataBuild