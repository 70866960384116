<template>
  <div>
    <el-tree
      :data="treeData"
      show-checkbox
      node-key="typeId"
      default-expand-all
      :props="defaultProps"
      :expand-on-click-node="false"
    >
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span
          >{{ node.label }}
          <el-image
            v-if="data.typeIcon"
            class="type_image"
            :src="imageRootPath + data.typeIcon"
            :preview-src-list="[imageRootPath + data.typeIcon]"
          ></el-image>
        </span>

        <span class="controller">
          <el-button
            type="text"
            size="mini"
            @click="() => remove(node, data)"
            v-if="
              data.typeNumber != '-100' &&
              (!data.children || data.children.length == 0)
            "
          >
            删除
          </el-button>

          <el-button type="text" size="mini" v-if="data.typeNumber != '-100'"  @click="() => edit(data)">
            编辑
          </el-button>
          <el-button type="text" size="mini" @click="() => append(data)">
            增加
          </el-button>
        </span>
      </span>
    </el-tree>

    <el-dialog title="商品类型" :visible.sync="dialogFormVisible" width="450px">
      <el-form
        :model="form"
        ref="addOrUpdateGoodsTypeForm"
        :rules="addOrUpdateGoodsTypeRules"
      >
        <el-form-item
          label="类型名称"
          :label-width="formLabelWidth"
          prop="typeName"
        >
          <el-input
            type="typeName"
            maxlength="30"
            v-model.trim="form.typeName"
            autocomplete="off"
            clearable
            placeholder="请输入类型名称，最多30个字"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="描述信息"
          :label-width="formLabelWidth"
          prop="typeDesc"
        >
          <el-input
            type="textarea"
            maxlength="255"
            v-model.trim="form.typeDesc"
            autocomplete="off"
            clearable
            placeholder="请输入描述信息，最多255个字"
          ></el-input>
        </el-form-item>

        <el-form-item label="上传图标" :label-width="formLabelWidth">
          <el-upload
            action=""
            class="avatar-uploader"
            :before-upload="beforeUpload"
            :limit="1"
            accept="image/png,image/jpeg"
            :show-file-list="false"
            ref="imageUploadBtn"
            :disabled="imageUploadIng"
            :http-request="fileuploadHandle"
          >
            <img
              v-if="form.typeIcon"
              :src="imageRootPath + form.typeIcon"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

            <!-- <el-button
              size="small"
              type="primary"
              :disabled="imageUploadIng"
              :loading="imageUploadIng"
              >点击上传</el-button
            > -->
            <!-- <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div> -->
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="addOrUpdateGoodsTypeHandle"
          :disabled="imageUploadIng"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAllGoodsTypeList,
  deleteGoodsType,
  addOrUpdateGoodsType,
} from "../../../api/goodsType/index";

import { fileupload } from "../../../api/uploadfile/index";

import gootsTypeDataBuild from "../../../util/gootsTypeDataBuild";

export default {
  data() {
    return {
      imageUploadIng: false, //正在处理批量上传
      dialogFormVisible: false,
      formLabelWidth: "100px",
      form: {
        //	商品类型请求参数
        typeId: "", //长度32,UUID
        parentNumber: "", //parentNumber 长度100 格式999100101
        typeName: "", //	typeName 长度30
        typeDesc: "", //描述信息 长度255
        typeIcon: "", //图标
      },
      addOrUpdateGoodsTypeRules: {
        typeName: [
          {
            required: true,
            message: "类型名必须填写",
            trigger: "blur",
          },
        ],
      },
      treeData: [],
      defaultProps: {
        children: "children",
        label: "typeName",
      },
    };
  },
  mounted() {
    this.getAllGoodsTypeData();
  },
  methods: {
    //获取所有数据
    getAllGoodsTypeData() {
      getAllGoodsTypeList()
        .then((res) => {
          if (res.code == 1) {
            let list = res.data;
            if (list.length > 0) {
              let { menu } = gootsTypeDataBuild(list);
              this.treeData = menu;
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    beforeUpload(file) {
      this.imageUploadIng = true;
    },
    fileuploadHandle(params) {
      let formData = new FormData();
      formData.append("file", params.file);
      fileupload(formData)
        .then((res) => {
          this.$refs["imageUploadBtn"].clearFiles();
          if (res.code == 1) {
            //清空已上传的文件列表（该方法不支持在 before-upload 中调用）
            let data = res.data;
            // let paramers = {
            //   fileName: data.nginxFileReadPath,
            //   originalFileName: data.originalFilename,
            //   onceBachCount: "-1",
            // };

            this.form.typeIcon = data.nginxFileReadPath;
          } else if (res.code == -1) {
            //未登陆
            this.$router.push("/");
          } else {
            this.$message.error(res.msg);
          }
          this.imageUploadIng = false;
        })
        .catch((e) => {
          this.$refs["imageUploadBtn"].clearFiles();
          this.imageUploadIng = false;
        });
    },
    //处理数据新增或者修改保存
    addOrUpdateGoodsTypeHandle() {
      this.$refs["addOrUpdateGoodsTypeForm"].validate((volid) => {
        if (volid) {
          addOrUpdateGoodsType(this.form)
            .then((res) => {
              if (res.code == 1) {
                this.dialogFormVisible = false;
                this.getAllGoodsTypeData();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((e) => {
              this.$message.error(e);
            });
        }
      });
    },
    //编辑
    edit(data){
 //图标
      this.form.typeName = data.typeName; //	typeName 长度30
      this.form.typeDesc = data.typeDesc; //描述信息 长度255
      this.form.typeIcon = data.typeIcon,
      this.form.typeId = data.typeId;
      /**
       * 注意 不要乱改 这个data.parentNumber 和下面的data.typeNumber 是两个概念，
       * 这里是修改，提交的parentNumber 等于当前节点的parentNumber
       */
      this.form.parentNumber = data.parentNumber;
      this.dialogFormVisible = true;
    },
    append(data) {
     
      this.form.typeName = ""; //	typeName 长度30
      this.form.typeDesc = ""; //描述信息 长度255
      this.form.typeIcon = "",
      this.form.typeId = this.$uuidv4().replace(/\-/g, "");
       /**
       * 
       * 这里是在父级节点新增，所以 提交的parentNumber  等于当前这个节点的 typeNumber 
       */
      this.form.parentNumber = data.typeNumber;
      this.dialogFormVisible = true;

      // console.log(data)
      // const newChild = { typeId: 100011, typeName: "testtest", children: [] };
      // if (!data.children) {
      //   this.$set(data, "children", []);
      // }
      // data.children.push(newChild);
    },

    remove(node, data) {
      this.$confirm("确定删除商品类型：" + data.typeName + "？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteGoodsType({ typeId: data.typeId })
            .then((res) => {
              if (res.code == 1) {
                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const index = children.findIndex(
                  (d) => d.typeId === data.typeId
                );
                children.splice(index, 1);
              }
            })
            .catch((e) => {});
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-right: 8px;
  .type_image {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    vertical-align: middle;
  }
  .controller {
    margin-left: 10px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
