import request from "@/base/util/request.js";
export const getAllGoodsTypeList = (params) => {
    return request({
            url: "shoppingmallbackstage/backstage/goodstype/getAllGoodsTypeList",
            method: "get",
            params,
        })
        // POST /com/user/updatePassword
        // 修改密码
        // export const updatePassword = (data) => {
        //     return request({
        //         url: "/com/user/updatePassword",
        //         method: "post",
        //         data,
        //     })
        // }
}

/**
 * 删除一个商品类型
 * @param {*} params 
 * @returns 
 */
export const deleteGoodsType = (params) => {
    return request({
        url: "shoppingmallbackstage/backstage/goodstype/deleteGoodsType",
        method: "get",
        params,
    })
}


/**
 * 添加或者修改商品类型信息
 * @param {*} data 
 * @returns 
 */
export const addOrUpdateGoodsType = (data) => {
    return request({
        url: "shoppingmallbackstage/backstage/goodstype/addOrUpdateGoodsType",
        method: "post",
        data,
    })
}